
const path = '/acid-rain-world/89fc62fa/';

export const bookurl = {
    '0.0': path + 'a89b1859',

    '1.1': path + '87ae506b',
    '1.2': path + 'dfd23656',
    '1.3': path + '37b2682c',

    '2.1': path + '24ce593b',
    '2.2': path + '09b6c0be',
    '2.3': path + '7bf09fd6',

    '3.1': path + '84f52949',
    '3.2': path + 'a73cdb27',
    '3.3': path + '8bd5b21b',

    '4.1': path + '41c5bfc7',
    '4.2': path + '769834e2',
    '4.3': path + '3476f6ea',

    '5.1': path + '18d3728f',
    '5.2': path + '884826cf'
};

export const book = {

    'chp1': {    "en": "Chapter 1",                       "zh-hk": "第一章",            "zh-cn": "第一章" },
    'chp2': {    "en": "Chapter 2",                       "zh-hk": "第二章",            "zh-cn": "第二章" },
    'chp3': {    "en": "Chapter 3",                       "zh-hk": "第三章",            "zh-cn": "第三章" },
    'chp4': {    "en": "Chapter 4",                       "zh-hk": "第四章",            "zh-cn": "第四章" },
    'chp5': {    "en": "Chapter 5",                       "zh-hk": "第五章",            "zh-cn": "第五章" },

            '0.0': {    "en": "Prologue",                       "zh-hk": "序章",            "zh-cn": "序章" },

            '1': {      "en": "Rainstorm at the frontier",      "zh-hk": "暴雨邊疆",        "zh-cn": "暴雨边疆" },
            '1.1': {    "en": "The Taste of Coffee",            "zh-hk": "咖啡的味道",      "zh-cn": "咖啡的味道"   },
            '1.2': {    "en": "The First Battle",               "zh-hk": "初陣",            "zh-cn": "初阵" },
            '1.3': {    "en": "Jazz Blues",                     "zh-hk": "藍調爵士",        "zh-cn": "蓝调爵士" },

            '2': {      "en": "The Veteran’s Final Mission",    "zh-hk": "老兵的最後任務",  "zh-cn": "老兵的最后任务"   },
            '2.1': {    "en": "A Midnight Assignment",          "zh-hk": "夜半出動",        "zh-cn": "夜半出动"     },
            '2.2': {    "en": "Deer Season",                    "zh-hk": "獵鹿季",       "zh-cn": "猎鹿季"          },
            '2.3': {    "en": "Passing It On",                  "zh-hk": "傳承",        "zh-cn": "传承"            },

            '3': {      "en": "Nothing Ventured",       "zh-hk": "別呆坐等待",  "zh-cn": "别呆坐等待"       },
            '3.1': {    "en": "Useful or not",          "zh-hk": "有用無用",    "zh-cn": "有用无用"         },
            '3.2': {    "en": "Taking out the trash",   "zh-hk": "清除垃圾",    "zh-cn": "清除垃圾"         },
            '3.3': {    "en": "Tartarus",               "zh-hk": "死獄鬥甲場",    "zh-cn": "死狱斗甲场"     },

            '4': {      "en": "The Last Order",     "zh-hk": "最後的命令",    "zh-cn": "最后的命令"     },
            '4.1': {    "en": "The code",           "zh-hk": "密碼",        "zh-cn": "密码"            },
            '4.2': {    "en": "Maze in the Sands",  "zh-hk": "沙漠迷宮",    "zh-cn": "沙漠迷宫"         },
            '4.3': {    "en": "Gun Salute",         "zh-hk": "禮炮",        "zh-cn": "礼炮"            },

            '5': {      "en": "Disposables",            "zh-hk": "消耗品",              "zh-cn": "消耗品"            },
            '5.1': {    "en": "You are what you eat",   "zh-hk": "人如其食",            "zh-cn": "人如其食"            },
            '5.2': {    "en": "Pawns In Their Game?",   "zh-hk": "我們只是他們的工具嗎",    "zh-cn": "我们只是他们的工具吗" },

            "bookTitle": {
                "en": "Acid Rain World",
                "zh-hk": "酸雨戰爭",
                "zh-cn": "酸雨战争"
            }
        };

export const messages = {
            "en": {
                // "TEXT180007": "Story",
                // "TEXT180008": "TABLE OF CONTENTS",
                // "TEXT180009": "Season %s",
                "TEXT180010": "Session Menu",
                "TEXT180011": "Previous Chapter",
                "TEXT180012": "Next chapter",
                // "TEXT180013": "Chapter %s",
                // "TEXT181000": "LATEST SESSION",
                // "TEXT181001": "READ FROM BEGINNING",
                // "TEXT181002": "LATEST SESSION",
                "TEXT181003": "READ NOW",

                "txtDescription": "Even after a nuclear holocaust, war continued to rage across the planet, further destroying the environment and draining the world of its resources. As old borders dissolved, new city states run by conglomerates and warlords emerged. Driven by their greed and lust for power, they liberally utilized weapons of mass destruction in their personal conquests. The resulting devastation triggered a vengeful change in the climates, which brought the world into a bleak and cruel age; what remained of humanity named this age “The Acid Rain Era”.",
                "txtAuthor": "Perl Grey",
                "labelAuthor": "Author",
                "txtCreator": "Kit Lau",
                "labelCreator": "Original Story"
            },
            "zh-hk": {
                // "TEXT180007": "故事連載",
                // "TEXT180008": "故事目錄",
                // "TEXT180009": "第%s季",
                "TEXT180010": "所有章節",
                "TEXT180011": "上一章",
                "TEXT180012": "下一章",
                // "TEXT180013": "第%s章",
                // "TEXT181000": "最新章節",
                // "TEXT181001": "開始閱覽",
                // "TEXT181002": "最新章節",
                "TEXT181003": "立即閱覽",

                "txtDescription": "在核浩劫之後，全球戰爭依然持續肆虐，對環境造成進一步破壞，世界資源趨於耗盡。隨著原有國界被瓦解，新的城市國家相繼誕生，並由大企業和軍閥管治。他們在貪念和欲望的驅使下，肆意使用大範圍殺傷性武器，以滿足他們個人的征服感。無盡的破壞引發了大自然的報復，氣候的轉變將世界帶入了一個蒼涼而又殘酷的時代，人們稱之為“酸雨時代”。",
                "txtAuthor": "佩格雷",
                "labelAuthor": "小說作者",
                "txtCreator": "劉斯傑",
                "labelCreator": "原作"
            },
            "zh-cn": {
                // "TEXT180007": "故事连载",
                // "TEXT180008": "故事目录",
                // "TEXT180009": "第%s季",
                "TEXT180010": "所有章节",
                "TEXT180011": "上一章",
                "TEXT180012": "下一章",
                // "TEXT180013": "第%s章",
                // "TEXT181000": "最新章节",
                // "TEXT181001": "开始阅览",
                // "TEXT181002": "最新章节",
                "TEXT181003": "立即阅览",

                "txtDescription": "在核浩劫之后，全球战争依然持续肆虐，对环境造成进一步破坏，世界资源趋于耗尽。随着原有国界被瓦解，新的城市国家相继诞生，并由大企业和军阀管治。他们在贪念和欲望的驱使下，肆意使用大范围杀伤性武器，以满足他们个人的征服感。无尽的破坏引发了大自然的报复，气候的转变将世界带入了一个苍凉而又残酷的时代，人们称之为“酸雨时代”。",
                "txtAuthor": "佩格雷",
                "labelAuthor": "小说作者",
                "txtCreator": "刘斯杰",
                "labelCreator": "原作"
            }
        };
