import React, { Component } from 'react'

import {
  NavDropdown,
  Nav
} from 'react-bootstrap'


import { book, bookurl, messages } from './../util/book.js'
import './sessionMenu.css'


class SessionMenu extends Component {

    render() {
        const lang = this.props.lang || 'en'
        const path = '/'+lang
        const sid = {}
        sid[this.props.select] = "active";
        // console.log(sid)

        return (
            <>
            <Nav id="sessionMenu">
                <NavDropdown title={messages[lang].TEXT180010} id="dropdown-world">
                    <NavDropdown.Item href={path+bookurl["0.0"]} className={sid["0.0"]}>0.0 &nbsp; {book["0.0"][lang]}</NavDropdown.Item>
                    
                    <NavDropdown.Item href={path+bookurl["1.1"]} className={sid["1.1"]}>1.1 &nbsp; {book["1.1"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["1.2"]} className={sid["1.2"]}>1.2 &nbsp; {book["1.2"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["1.3"]} className={sid["1.3"]}>1.3 &nbsp; {book["1.3"][lang]}</NavDropdown.Item>

                    <NavDropdown.Item href={path+bookurl["2.1"]} className={sid["2.1"]}>2.1 &nbsp; {book["2.1"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["2.2"]} className={sid["2.2"]}>2.2 &nbsp; {book["2.2"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["2.3"]} className={sid["2.3"]}>2.3 &nbsp; {book["2.3"][lang]}</NavDropdown.Item>

                    <NavDropdown.Item href={path+bookurl["3.1"]} className={sid["3.1"]}>3.1 &nbsp; {book["3.1"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["3.2"]} className={sid["3.2"]}>3.2 &nbsp; {book["3.2"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["3.3"]} className={sid["3.3"]}>3.3 &nbsp; {book["3.3"][lang]}</NavDropdown.Item>

                    <NavDropdown.Item href={path+bookurl["4.1"]} className={sid["4.1"]}>4.1 &nbsp; {book["4.1"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["4.2"]} className={sid["4.2"]}>4.2 &nbsp; {book["4.2"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["4.3"]} className={sid["4.3"]}>4.3 &nbsp; {book["4.3"][lang]}</NavDropdown.Item>

                    <NavDropdown.Item href={path+bookurl["5.1"]} className={sid["5.1"]}>5.1 &nbsp; {book["5.1"][lang]}</NavDropdown.Item>
                    <NavDropdown.Item href={path+bookurl["5.2"]} className={sid["5.2"]}>5.2 &nbsp; {book["5.2"][lang]}</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            </>
        )
    }

}

export default SessionMenu
