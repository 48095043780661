import React, { Component } from 'react'
// import { StaticQuery, graphql } from 'gatsby'
// import { CopyToClipboard } from 'react-copy-to-clipboard'

// import {
//   Dropdown, Button,
//   NavDropdown
// } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faShareAlt, faLink } from '@fortawesome/free-solid-svg-icons'
// import { faWeibo, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { 
    // faHome, 
    faFont  } from '@fortawesome/free-solid-svg-icons'

import FontSizeChanger from 'react-font-size-changer';

// import './share.css'


class TypefaceResize extends Component {
    constructor(props) {
        super(props)

        this.state = {
            _fsc: null
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            const target = this.props.target || ''
            // console.log('***** target ********')
            // console.log( target )
            this.setState({
                _fsc: <FontSizeChanger
                    targets={[target]}
                    options={{ stepSize: 2, range: 4 }}
                    customButtons={{
                        up: <FontAwesomeIcon icon={faFont} />,
                        down: <FontAwesomeIcon icon={faFont} size="xs" />,
                        style: {
                            // color: 'white',
                            WebkitBoxSizing: 'border-box',
                            WebkitBorderRadius: '5px',
                            width: '30px',
                            border: 0
                        }
                        , buttonsMargin: 0
                    }}
                />
            })
        }
    }

    render() {
        return (
            <>
                <div className="rciMenu">
                    {this.state._fsc}
                </div>
            </>
        )

    }

}



export default TypefaceResize
