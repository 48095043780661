import React, { Component } from 'react'
// import {
//   Form, FormControl, Button,
//   NavDropdown, Navbar, Nav
// } from 'react-bootstrap'
// import Media from 'react-media'
import posed from 'react-pose'
import { StaticQuery, graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
// import { Link, Language } from '@sckit/gatsby-plugin-i18next'

import MobileMenu from './mobile'
import DesktopMenu from './desktop'

import './menu.css'

const SwitchMenu = posed.div({
    initialPose: 'hidden',
    show: {
        delayChildren: 300,
        transition: { duration: 300 }
    },
    hidden: {
        transition: { duration: 300 }
    }
})

const SwitchMenuChild = posed.div({
    initialPose: 'hidden',
    show: {
        opacity: 1,
        transition: { duration: 1000 }
    },
    hidden: {
        opacity: 0,
        transition: { duration: 300 }
    }
})

const SiteMenu = ({width, parent}) => {
    return (
        <StaticQuery
            query={graphql`{
                site {
                    siteMetadata {
                        siteUrl
                        brandSiteUrl
                    }
                }
            }`}
            render={(data) => {
                const siteUrl = data.site.siteMetadata.siteUrl
                const brandSiteUrl = data.site.siteMetadata.brandSiteUrl
                const uri = (siteUrl === brandSiteUrl) ? siteUrl : brandSiteUrl
                let __m = null

                if (width > 1199) {
                    __m = <DesktopMenu {...parent.props} pstate={parent.state} uri={uri} onRef={e => {
                            parent.setState({menuCom: e})
                        }} />
                } else {
                    __m = <MobileMenu {...parent.props} pstate={parent.state} uri={uri} onRef={e => {
                            parent.setState({menuCom: e})
                        }} />
                }

                return __m
            }}
        />
    )
}

class Menu extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            id: (+new Date()),
            scrollTop: 0,
            css: {
                elemNavBar: {
                    scrolled: false
                },
                elemNavTop: {
                    show: true
                },
                elemLangMenu: {
                    show: false
                },
                elemMainMenu: {
                    show: false
                }
            },
            topNavAttr: {},
            topNavBrandAttr: {},
            width: 0,
            menu: null,
            menuCom: null
        }
        this._handleScroll = this._handleScroll.bind(this)
        this._handleResize = this._handleResize.bind(this)
    }

    componentDidMount() {
        // console.log(`mount`)
        window.addEventListener('scroll', this._handleScroll)
        window.addEventListener('resize', this._handleResize)

        this._handleScroll(() => {
            // Set Menu
            let _m = null
            _m = <SiteMenu width={window.innerWidth} parent={this} />
            this.setState({menu: _m})
        })

    }

    componentWillUnmount() {
        // console.log(`will unmount`)
        window.removeEventListener('scroll', this._handleScroll)
        window.removeEventListener('resize', this._handleResize)
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.state)
        if (prevState.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }

    shouldComponentUpdate() {
        // console.log('shouldComponentUpdate')
        return true
    }

    _handleScroll(cb) {
        cb = ( (typeof cb === 'function') && cb ) || null
        const latestScrollTop = window.pageYOffset
        // For Scrolled Event: Resize Top Nav
        let updateData = Object.assign({}, this.state)
        if (latestScrollTop > 40) {
            updateData.scrollTop = latestScrollTop
            updateData.css.elemNavBar.scrolled = true
            updateData.css.elemNavTop.show = false
        }
        // Return
        if (latestScrollTop < 40) {
            updateData.scrollTop = latestScrollTop
            updateData.css.elemNavBar.scrolled = false
            updateData.css.elemNavTop.show = true
        }
        // For more than 1200 & Scrolled : Hide Main Menu
        if (window.innerWidth > 1200) {
            if (latestScrollTop > 40) {
                updateData.scrollTop = latestScrollTop
                updateData.css.elemNavBar.scrolled = true
                updateData.css.elemNavTop.show = false
            }
        }

        updateData.topNavAttr = (updateData.css.elemNavBar.scrolled) ? 'scrolled' : 'init'
        updateData.topNavBrandAttr = (updateData.css.elemNavBar.scrolled) ? 'scrolled' : 'init'
        updateData.id = (+new Date())
        if (cb === null) {
            this.setState(updateData, () => {
                if (this.state.menuCom !== null) {
                    this.state.menuCom.update(this.state)
                }
            })
        } else {
            this.setState(updateData, cb)
        }
    }

    _handleResize() {
        this.setState({width: window.innerWidth})
    }

    render() {

        return (
            <>
            {/*--=============  Top Nav  =============--*/}
            <header>
                <span id="top"></span>
                <SwitchMenu pose={(this.state.width > 0) ? 'show' : 'hidden'}>
                    <SwitchMenuChild>
                        {this.state.menu}
                    </SwitchMenuChild>
                </SwitchMenu>
            </header>
            {/*--=============  / Top Nav  =============--*/}
            </>
        )
    }
}

export default withNamespaces()(Menu)
