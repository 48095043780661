import React, { Component } from 'react'
import {
//   Form, FormControl, 
  Button,
  NavDropdown, Navbar, Nav
} from 'react-bootstrap'
import { Link, Language } from '@sckit/gatsby-plugin-i18next'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import LocalImage from '../local-image'
// import SearchPanel from '../search-panel'
import LangSwitcher from '../lang-switcher'

// import './menu.css'

const topNavStateStyle = {
    init: {
        background: 'linear-gradient( rgba(0,0,0,100) , rgba(0,0,32,0))',
        padding: '1rem 0',
        height: '80px'
    },
    open: {
        background: '#000000'
    },
    close: {
    },
    scrolled: {
        background: '#000000',
        padding: '0.5rem 0',
        height: '50px'
    }
}

const topNavBrandStyle = {
    init: {
        height: '3rem'
    },
    open: {
        height: '100%'
    },
    scrolled: {
        height: '100%'
    }
}

class MobileMenu extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = this.props.pstate
        this._handleMenuCollapse = this._handleMenuCollapse.bind(this)
        this.targetElement = null
    }

    componentDidMount() {
        // console.log(`mount`)
        this.props.onRef(this)
        // Persist scrolling element while body scroll locked
        this.targetElement = document.querySelector('#navTop');
    }

    componentWillUnmount() {
        // console.log(`will unmount`)
        this.props.onRef(undefined)
        clearAllBodyScrollLocks();
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.id !== this.props.pstate.id) {
        //     this.setState(this.props.pstate)
        // }
    }

    update(pstate) {
        if (this.state.id !== pstate.id) {
            this.setState({
                ...pstate
            })
        }
    }

    _handleMenuCollapse(e) {
        const elem = e.currentTarget || e
        if (elem.hasAttribute('aria-controls')) {
            const controlMenuID = elem.getAttribute('aria-controls')
            let updateData = Object.assign({}, this.state)
            switch (controlMenuID) {
                case "langMenu":
                    updateData.css.elemLangMenu.show = (updateData.css.elemLangMenu.show === true ? false : true)
                    updateData.css.elemMainMenu.show = (updateData.css.elemMainMenu.show === true ? false : false)
                    break;
                case "mainMenu":
                    updateData.css.elemMainMenu.show = (updateData.css.elemMainMenu.show === true ? false : true)
                    updateData.css.elemLangMenu.show = (updateData.css.elemLangMenu.show === true ? false : false)
                    break;

                default:
            }
            if (updateData.css.elemMainMenu.show === true || updateData.css.elemLangMenu.show === true  ){
                disableBodyScroll(this.targetElement);
            }else{
                enableBodyScroll(this.targetElement);
            }

            updateData.topNavAttr = ( ( (updateData.css.elemMainMenu.show || updateData.css.elemLangMenu.show) ? 'open' : (updateData.css.elemNavBar.scrolled) ) && 'scrolled' ) || 'init'
            updateData.topNavBrandAttr = ( ( (updateData.css.elemMainMenu.show || updateData.css.elemLangMenu.show) ? 'open' : (updateData.css.elemNavBar.scrolled) ) && 'scrolled' ) || 'init'
            this.setState(updateData)
        }
    }

    render() {
        const t = this.props.t
        const lang = this.props.i18n.language
        const uri = this.props.uri
        let navTopShow = this.state.css.elemNavTop.show
        // let navBarScrolled = this.state.css.elemNavBar.scrolled
        let langMenuOpened = this.state.css.elemLangMenu.show
        let mainMenuOpened = this.state.css.elemMainMenu.show

        return (
            <>
            <Navbar id="navTop" expand="xl" fixed="top" variant="dark" className={navTopShow ? "show" : ""} >
                <div id="navTop_Menu" style={topNavStateStyle[this.state.topNavAttr]}><div className="wrapper navbar">
                    <a href={`${uri}/${lang}`} className="navbar-brand" style={topNavBrandStyle[this.state.topNavBrandAttr]}>
                        <LocalImage name={`logo_${lang}`} attr={{className: "img-fluid", alt:`${'MU000'} - ${'MU012'}`}} />

                    </a>
                    <div id="mobileMenu" className="iconMenu d-xl-none">
                        <div className="d-flex flex-row">

                            <div className="icon-box d-none">
                                <Link to="/member">
                                    <LocalImage name="user" attr={{className: "icon", alt:`${t('MU014')}`}} fluid={true} />
                                </Link>
                            </div>

                            <div className="icon-box">
                                <Button id="btn-dropdown-lang" variant="dark" className="navbar-dark navbar-toggler" onClick={this._handleMenuCollapse} aria-controls="langMenu">
                                    <LocalImage name="lang" attr={{className: "icon", alt:"Lang"}} fluid={true} />
                                </Button>
                            </div>

                            <div className="icon-box lang-toggle">
                                <Button id="dropdown-main" variant="dark" className="navbar-dark navbar-toggler" onClick={this._handleMenuCollapse} aria-controls="mainMenu">
                                    <LocalImage name="menu" attr={{className: "icon", alt:"Menu"}} fluid={true} />
                                </Button>
                            </div>

                        </div>
                    </div>

                    <Navbar.Collapse id="langMenuMobile" className={`lang-menu collapse navbar-collapse brand-menu ${langMenuOpened ? 'show' : ''}`} >
                        <LangSwitcher />
                    </Navbar.Collapse>

                    <Navbar.Collapse id="mainMenu" className={`collapse navbar-collapse brand-menu ${mainMenuOpened ? 'show' : ''}`} >

                        <div id="navMore" className="mx-auto">
                            <a className="ellipsis" href="#null" aria-label="Show Menu"><LocalImage name="dot" attr={{className: "img-fluid", alt:"Show Menu"}} fluid={true} /></a>
                        </div>

                        <Nav id="navMain" className="navbar-nav mr-auto">
                            <Language>
                            {lngProps => {
                                const currentLng = lngProps.lng
                                return (
                                    <>
                                    <Nav.Link className="" href={`${uri}/${currentLng}/news`}>{t('MU003')}</Nav.Link>
                                    <Nav.Link className="" href={`${uri}/${currentLng}/creator`}>{t('MU004')}</Nav.Link>

                                    <NavDropdown title={t('MU005')} id="dropdown-world">
                                        
                                        <NavDropdown.Item href={`${uri}/${currentLng}/codex`}>{t('MU006')}</NavDropdown.Item>
                                        <NavDropdown.Item href={`${uri}/${currentLng}/database`}>{t('MU007')}</NavDropdown.Item>
                                        <NavDropdown.Item href={`${uri}/${currentLng}/map`}>{t('MU008')}</NavDropdown.Item>

                                    </NavDropdown>

                                    {/* Comic */}
                                    {/* <Nav.Link id="dropdown-comic" className="" href="https://comic.aither.world/" target="_blank" rel="noopener noreferrer">{t('MU009')}</Nav.Link> */}

                                    <Nav.Link id="dropdown-story" className="" href="https://story.aither.world/" target="_blank" rel="noopener noreferrer">{t('MU010')}</Nav.Link>

                                    <Nav.Link className="" href={`${uri}/${currentLng}/products`}>{t('MU011')}</Nav.Link>

                                    <Nav.Link className="" href={`https://games.aitherentertainment.com/arwgraydawn/`} target="_blank" rel="noopener noreferrer">{t('MU015')}</Nav.Link>

                                    {/* Download  */}
                                    {/* <Nav.Link className="" href={`http://aitherentertainment.com/download`} rel="noopener noreferrer">{t('MU016')}</Nav.Link> */}
                                    

                                    <Nav.Link className="d-lg-none" href="https://aither.myshopify.com" target="_blank" rel="noopener noreferrer">{t('MU012')}</Nav.Link>

                                    <Nav.Link className="d-xl-none" href={`/${currentLng}/search`} rel="noopener noreferrer">{t('MU013')}</Nav.Link>

                                    <div className="navArrowClose nav-item d-xl-none">
                                        <Button id="btn_dropback_main" variant="dark" className="d-xl-none border-bottom-0 nav-link navbar-dark navbar-toggler" onClick={this._handleMenuCollapse} aria-controls="mainMenu"><LocalImage name="arrow" attr={{className: "icon", alt:""}} fluid={true} /></Button>
                                        <LocalImage name="nav_btm" attr={{className: "bar img-fluid w-100", alt:""}} fluid={true} />
                                    </div>
                                    </>
                                )
                            }}
                            </Language>
                        </Nav>
                    </Navbar.Collapse>


                </div></div>
            </Navbar>
            </>
        )
    }
}

export default MobileMenu
