import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
//   Dropdown, Button,
  NavDropdown
} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faLink } from '@fortawesome/free-solid-svg-icons'
import { faWeibo, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

import './share.css'





class Share extends Component {

    onClick = ({target: {innerHTML}}) => {
        // console.log(`Clicked on "${innerHTML}"!`); // eslint-disable-line
    }

    render() {
        const url = this.props.url || '/'
        const title = this.props.title || ''
        const pic = this.props.pic || ''
        const appkey = this.props.appkey || ''

        return (
            <StaticQuery
                query={graphql`{
                    site {
                        siteMetadata {
                            title
                            domain
                        }
                    }
                }`}
                render={(data) => {
                    const domain = data.site.siteMetadata.domain
                    const shareUrl = `https://${domain}${url}`
                    const urlWeibo = `http://service.weibo.com/share/share.php?url=${shareUrl}&title=${title}&pic=${pic}&appkey=${appkey}`
                    const urlTwitter = `https://twitter.com/intent/tweet?url=${shareUrl}&hashtags=ARW&via=acidrainworld`
                    const urlFb = `https://www.facebook.com/share.php?u=${shareUrl}`

                    return (
                        <>
                        {/*--=============  SNS Share  =============--*/}
                        <div className="rciMenu shareMenu">
                            <div className="iconMenu">
                                <NavDropdown title={<FontAwesomeIcon icon={faShareAlt} className="img-fluid" />}
                                        id="share" className="icon-box caret-off" alignRight>

                                    <NavDropdown.Item href={urlWeibo} target="_blank" rel="noopener noreferrer" >
                                        <FontAwesomeIcon icon={faWeibo} size="lg" />
                                    </NavDropdown.Item>

                                    <NavDropdown.Item href={urlFb} target="_blank" rel="noopener noreferrer" >
                                        <FontAwesomeIcon icon={faFacebook} size="lg" />
                                    </NavDropdown.Item>

                                    <NavDropdown.Item href={urlTwitter} target="_blank" rel="noopener noreferrer" >
                                        <FontAwesomeIcon icon={faTwitter} size="lg" />
                                    </NavDropdown.Item>

                                    <CopyToClipboard text={shareUrl}>
                                        <NavDropdown.Item href="#" onClick={this.onClick} data-clipboard-text={url} target="_blank" rel="noopener noreferrer" >
                                            <FontAwesomeIcon icon={faLink} size="lg" />
                                        </NavDropdown.Item>
                                    </CopyToClipboard>

                                </NavDropdown>
                            </div>
                        </div>
                        {/*--=============  / SNS Share  =============--*/}
                        </>
                )
            }}
            />
    )
    }

}



export default Share
