import React, { Component } from 'react'
// import _ from 'lodash'
import {
//   Form, FormControl, 
  Button, Dropdown,
  NavDropdown, Navbar, Nav,
  Container
} from 'react-bootstrap'
import posed from 'react-pose'
// import { navigate } from 'gatsby'
import { Language } from '@sckit/gatsby-plugin-i18next'

import LocalImage from '../local-image'
// import SearchPanel from '../search-panel'
import LangSwitcher from '../lang-switcher'

// import './menu.css'

const topNavStateStyle = {
    init: {
        background: 'linear-gradient( rgba(0,0,0,100) , rgba(0,0,32,0))',
        padding: '1rem 0',
        height: '100px'
    },
    open: {
        background: '#000000',
        // backgroundImage: `url(nav_btm_full.png)`,
        // backgroundImage: `url(${featureItem.banner_image.url})`,
        padding: '1rem 0',
        height: '100px'
    },
    close: {
    },
    scrolled: {
        background: '#000000',
        padding: '0.5rem 0',
        height: '50px'
    }
}

const topNavBrandStyle = {
    init: {
        height: '50px'
    },
    open: {
        height: '50px'
    },
    scrolled: {
        height: '100%'
    }
}

const RollingMenu = posed.div({
    initialPose: 'show',
    show: {
        y: '-4rem',
        transition: { duration: 500 }
    },
    hidden: {
        y: '-1rem',
        transition: { duration: 300 }
    }
})

class DesktopMenu extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            ...this.props.pstate,
            mainMenuAttr: 'show',
            currentToggleTarget: ''
        }
        this._handleMenuCollapse = this._handleMenuCollapse.bind(this)
        this.update = this.update.bind(this)
    }

    componentDidMount() {
        // console.log(`mount`)
        this.props.onRef(this)
    }

    componentWillUnmount() {
        // console.log(`will unmount`)
        this.props.onRef(undefined)
    }

    componentDidUpdate(prevProps, prevState) {
    }

    update(pstate) {
        if (this.state.id !== pstate.id) {
            const _mma = pstate.css.elemNavBar.scrolled ? 'hidden' : 'show'
            this.setState({
                ...pstate,
                mainMenuAttr: _mma
            })
        }
    }

    _handleMenuCollapse(e) {
        const elem = e.currentTarget || e
        if (elem.hasAttribute('aria-controls')) {
            const controlMenuID = elem.getAttribute('aria-controls')
            let updateData = Object.assign({}, this.state)
            switch (controlMenuID) {
                case "mainMenu":
                    updateData.mainMenuAttr = (updateData.mainMenuAttr === 'hidden') ? 'show' : 'hidden'
                    break;
                default:
            }
            updateData.topNavAttr = (updateData.css.elemNavBar.scrolled) ? 'open' : 'init'
            updateData.topNavBrandAttr = (updateData.css.elemNavBar.scrolled) ? 'open' : 'init'
            this.setState(updateData)
        }
    }

    _handleSubMenuNavigation(e) {
        const targetElem = e.currentTarget
        e.preventDefault()
        if (targetElem.hasAttribute('href')) {
            window.location.href = targetElem.getAttribute('href')
            // let url = targetElem.getAttribute('href').split('/').map((v, i) => {
            //     if (i > 3) {
            //         return v
            //     }
            // })
            // navigate("/"+_.compact(url).join('/'), { replace: true })
        }
    }

    render() {
        const t = this.props.t
        const lang = this.props.i18n.language
        const uri = this.props.uri
        let navTopShow = this.state.css.elemNavTop.show
        // let navBarScrolled = this.state.css.elemNavBar.scrolled
        // let langMenuOpened = this.state.css.elemLangMenu.show
        let mainMenuOpened = this.state.css.elemMainMenu.show

        return (
            <>
            <Navbar id="navTop" expand="xl" fixed="top" variant="dark" className={navTopShow ? "show" : ""} >
                <div id="navTop_Menu" style={topNavStateStyle[this.state.topNavAttr]}>
                    <Container className="wrapper">
                    <a href={`${uri}/${lang}`} className="navbar-brand" style={topNavBrandStyle[this.state.topNavBrandAttr]}>
                        <LocalImage name={`logo_${lang}`} attr={{className: "img-fluid", alt:`${'MU000'} - ${'MU012'}`}} />
                    </a>

                    <Navbar.Collapse id="mainMenu"
                        className={`collapse navbar-collapse brand-menu ${mainMenuOpened ? 'show' : ''}`}
                        style={{ flexDirection: 'column', overflowY: (this.state.mainMenuAttr === 'hidden') ? 'hidden' : 'inherit' }} >

                        <RollingMenu pose={this.state.mainMenuAttr}>
                            <div id="navMore" className="mx-auto d-flex justify-content-center">
                            <button className="ellipsis" onClick={this._handleMenuCollapse} aria-controls="mainMenu" aria-label="Show Menu"><LocalImage name="dot" attr={{className: "img-fluid", alt:"Show Menu"}} fluid={true} /></button>
                            </div>

                            <Nav id="navMain" className="navbar-nav mr-auto">
                            <Language>
                            {lngProps => {
                                const currentLng = lngProps.lng
                                return (
                                    <>
                                    <Nav.Link className="" href={`${uri}/${currentLng}/news`}>{t('MU003')}</Nav.Link>
                                    <Nav.Link className="" href={`${uri}/${currentLng}/creator`}>{t('MU004')}</Nav.Link>

                                    <Dropdown as={Nav.Item} id="dropdown-world"
                                        onToggle={(isOpen, event) => {
                                            let updateData = Object.assign({}, this.state)
                                            let navTopShow = updateData.css.elemNavTop.show
                                            let isSelf = (typeof event === 'object') ? (( (event.currentTarget.id === 'dropdown-world-toggle') && true ) || false) : false
                                            let selfShow = false

                                            if (isOpen && (typeof event === 'object')) {
                                                navTopShow = true
                                                selfShow = true
                                            } else if (navTopShow && !isSelf) {
                                                if (updateData.currentToggleTarget === 'langMenuDesktop') {
                                                    selfShow = true
                                                    navTopShow = true
                                                } else {
                                                    selfShow = false
                                                    navTopShow = false
                                                }
                                            } else {
                                                navTopShow = false
                                                selfShow = false
                                            }

                                            updateData.topNavAttr = (selfShow || navTopShow) ? 'open' : (updateData.css.elemNavBar.scrolled) ? 'open' : 'init'
                                            updateData.topNavBrandAttr = (selfShow || navTopShow) ? 'open' : (updateData.css.elemNavBar.scrolled) ? 'open' : 'init'

                                            updateData.css.elemNavTop.show = navTopShow
                                            if (isSelf) {
                                                updateData.currentToggleTarget = event.currentTarget.id
                                            } else {
                                                updateData.currentToggleTarget = ''
                                            }
                                            this.setState(updateData)
                                        }}>
                                        <Dropdown.Toggle id={`dropdown-world-toggle`} variant="topnav" className={`nav-link`} eventkey={null} active={false}>{t('MU005')}</Dropdown.Toggle>
                                        <Dropdown.Menu role={`menu`} rootCloseEvent={`click`}>

                                            <NavDropdown.Item href={`${uri}/${currentLng}/codex`}
                                                onClick={this._handleSubMenuNavigation}>{t('MU006')}</NavDropdown.Item>
                                            <NavDropdown.Item href={`${uri}/${currentLng}/database`}
                                                onClick={this._handleSubMenuNavigation}>{t('MU007')}</NavDropdown.Item>
                                            <NavDropdown.Item href={`${uri}/${currentLng}/map`}
                                                onClick={this._handleSubMenuNavigation}>{t('MU008')}</NavDropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {/* Comic */}
                                    {/* <Nav.Link id="dropdown-comic" className="" href="https://comic.aither.world/" target="_blank" rel="noopener noreferrer">{t('MU009')}</Nav.Link> */}
                                    
                                    <Nav.Link id="dropdown-story" className="" href="https://story.aither.world/" target="_blank" rel="noopener noreferrer">{t('MU010')}</Nav.Link>

                                    <Nav.Link className="" href={`${uri}/${currentLng}/products`}>{t('MU011')}</Nav.Link>

                                    <Nav.Link className="" href={`https://games.aitherentertainment.com/arwgraydawn/`} target="_blank" rel="noopener noreferrer">{t('MU015')}</Nav.Link>

                                    {/* Download  */}
                                    {/* <Nav.Link className="" href={`http://aitherentertainment.com/download`} rel="noopener noreferrer">{t('MU016')}</Nav.Link> */}


                                    <Nav.Link className="d-none" href="https://aither.myshopify.com" target="_blank" rel="noopener noreferrer">{t('MU012')}</Nav.Link>

                                    <Nav.Link className="d-none" href={`/${currentLng}/search`} rel="noopener noreferrer">{t('MU013')}</Nav.Link>

                                    <div className="navArrowClose nav-item d-lg-none">
                                        <Button id="btn_dropback_main" variant="dark" className="d-lg-none border-bottom-0 nav-link navbar-dark navbar-toggler" onClick={this._handleMenuCollapse} aria-controls="mainMenu"><LocalImage name="arrow" attr={{className: "icon", alt:""}} fluid={true} /></Button>
                                        <LocalImage name="nav_btm" attr={{className: "bar img-fluid", alt:""}} fluid={true} />
                                    </div>
                                    </>
                                )
                            }}
                            </Language>
                        </Nav>
                        </RollingMenu>
                    </Navbar.Collapse>

                    <div id="desktopMenu" className="iconMenu d-none d-xl-block">
                        <div className="d-flex flex-row">

                            <div className="icon-box d-none"><a href="/member/" aria-label="Member"><LocalImage name="user" attr={{className: "icon", alt:`${t('MU014')}`}} fluid={true} /></a></div>

                            <div className="icon-box"> <a href="/search/" aria-label="Search"><LocalImage name="search" attr={{className: "icon", alt:`${t('MU013')}`}} fluid={true} /></a></div>

                            <div className="icon-box"><a href="https://aither.myshopify.com" target="_blank" rel="noopener noreferrer" aria-label="Shop"><LocalImage name="shop" attr={{className: "icon", alt:`${t('MU012')}`}} fluid={true} /></a></div>

                            <Dropdown as={Nav.Item} className="icon-box lang-toggle caret-off"
                                onToggle={(isOpen, event) => {
                                    let updateData = Object.assign({}, this.state)
                                    let navTopShow = updateData.css.elemNavTop.show
                                    let isSelf = (typeof event === 'object') ? (( (event.currentTarget.id === 'langMenuDesktop') && true ) || false) : false
                                    let selfShow = false

                                    if (isOpen && (typeof event === 'object')) {
                                        navTopShow = true
                                        selfShow = true
                                    } else if (navTopShow && !isSelf) {
                                        if (updateData.currentToggleTarget === 'dropdown-world-toggle') {
                                            selfShow = true
                                            navTopShow = true
                                        } else {
                                            selfShow = false
                                            navTopShow = false
                                        }
                                    } else {
                                        navTopShow = false
                                        selfShow = false
                                    }

                                    updateData.mainMenuAttr = (isOpen) ? 'show' : (updateData.css.elemNavBar.scrolled) ? 'hidden' : 'show'
                                    updateData.topNavAttr = (selfShow || navTopShow) ? 'open' : (updateData.css.elemNavBar.scrolled) ? 'scrolled' : 'init'
                                    updateData.topNavBrandAttr = (selfShow || navTopShow) ? 'open' : (updateData.css.elemNavBar.scrolled) ? 'scrolled' : 'init'

                                    updateData.css.elemNavTop.show = navTopShow
                                    if (isSelf) {
                                        updateData.currentToggleTarget = event.currentTarget.id
                                    } else {
                                        updateData.currentToggleTarget = ''
                                    }
                                    this.setState(updateData)
                                }}>
                                <Dropdown.Toggle id={`langMenuDesktop`} variant="topnav" className={`nav-link`} eventkey={null} active={false}>
                                    {<LocalImage name="lang" attr={{className: "icon", alt:"Lang"}} fluid={true} />}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="lang-menu" role={`menu`} rootCloseEvent={`click`}>
                                    <LangSwitcher />
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    </div>
                </Container>
                </div>
            </Navbar>
            </>
        )
    }
}

export default DesktopMenu
